import {PathBuilder} from '../PathBuilder';

export class MevLinkBuilder extends PathBuilder {
  public blocks() {
    return new PathBuilder([...this.path, 'blocks']);
  }

  public validators() {
    return new PathBuilder([...this.path, 'validators']);
  }
}
